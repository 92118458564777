// -------
// Shared styled components for Partners page and Loans single
// -------

import styled, { css, createGlobalStyle } from 'styled-components'
import { Link } from 'gatsby'
import ProgressiveImage from 'react-progressive-image'
import { media } from './utils'
import tw from 'tailwind.macro'
import { container, margins, padding, boxShadow, button } from './global'

// Shared Child Components

export const Heading = styled.div``
export const Subheading = styled.div``
export const Description = styled.div``
export const Info = styled.div``
export const BGColor = styled.div``
export const Button = styled(Link)`
    ${button};
`
export const ExternalLink = styled.a`
    ${button};
`

export const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

export const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

export const Wrapper = styled.div`
    ${tw`overflow-hidden`};
`

export const Container = styled.div`
    ${container}
    ${padding}
`

export const Grid = styled.div`
    ${tw`flex`};
`
export const Left = styled.div``
export const Right = styled.div``
export const Top = styled.div``
export const Bottom = styled.div``

// Top Layout

export const Introduction = styled.div`
    ${tw`bg-darkBlue`};

    > ${Grid} {
        ${tw`relative`};
        ${container}
        ${padding}

        ${media.mob`
            ${tw`flex-col px-0`};
        `}
    }

    ${Left} {
        ${tw`flex flex-col`};
    }

    /* Loan Widget */
    ${Right} {
        ${tw`absolute z-10 right-0 w-1/3 p-8 bg-orange text-white`};
        ${margins};
        ${boxShadow};
        top: 4rem;
        width: calc(33.3333% - 4rem);

        ${media.tabPor`
            ${tw`w-2/5`};
            width: calc(40% - 4rem);
        `}

        ${media.mob`
            display: none;
        `}
    }
`

// Intro

export const Intro = styled.div`
    ${tw`relative py-16 text-white`};

    ${media.mob`
        ${padding};
    `}

    ${Heading} {
        ${tw`w-2/3 pr-8`};
        font-weight: 500; /* medium */
        font-size: 1.1875rem;
        line-height: 1.29;

        ${media.tabPor`
            ${tw`w-3/5`};
        `}

        ${media.mob`
            ${tw`w-full pr-0`};
        `}
    }

    ${Description} {
        ${tw`w-2/3 mt-8 pr-8`};
        font-weight: 300; /* light */
        font-size: 14px;
        line-height: 1.57;

        ${media.tabPor`
            ${tw`w-3/5`};
        `}

        ${media.mob`
            ${tw`w-full pr-0`};
        `}
    }
`

// Features

export const Features = styled.div`
    ${tw`relative pt-16 pb-24`};

    ${media.mob`
        ${padding};
    `}

    ${BGColor} {
        width: 100vw;
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        height: 100%;
        margin-left: -50vw;
        margin-right: -50vw;
        ${tw`bg-lightBlue`};

        ${media.mob`
            ${tw`inset-0 mx-0`};
        `}
    }

    > *:not(.bg-color) {
        ${tw`relative z-10`};
    }

    ${Heading} {
        ${tw`w-2/3 leading-none text-orange text-center`};
        font-weight: 600; /* semibold */
        font-size: 1.625rem;

        ${media.tabPor`
            ${tw`w-3/5`};
        `}

        ${media.mob`
            ${tw`w-full`};
        `}
    }

    ${Grid} {
        ${tw`justify-center flex-wrap w-2/3 mt-12 -mb-6 px-8`};

        ${media.tabPor`
            ${tw`w-3/5 px-0`};
        `}

        ${media.mob`
            ${tw`w-full`};
        `}
    }
`

export const Feature = styled.div`
    ${tw`w-1/3 flex flex-col items-center px-6 text-center`};
    margin-bottom: 24px;

    ${media.mob`
        margin-bottom: 32px;
    `}

    ${LoadedImage} {
        flex-grow: 1;
    }

    ${Description} {
        min-height: 42px;
        ${tw`mt-4 text-darkBlue`};
        font-weight: 700; /* bold */
        font-size: 14px;
        line-height: 1.3;
    }
`

// Main

export const Main = styled.div`
    ${tw`pt-16 pb-48 bg-white`};

    ${media.mob`
        ${tw`pb-32`};
    `}

    ${Grid} {
        ${tw`justify-between`};

        ${media.mob`
            ${tw`block`};
        `}
    }

    ${Left} {
        ${tw`w-2/3 max-w-2xl pr-16`};

        ${media.tabPor`
            ${tw`w-3/5`};
        `}

        ${media.mob`
            ${tw`w-full pr-0`};
        `}

        ${Heading} {
            ${tw`text-orange`};
            font-weight: 600; /* semibold */
            font-size: 1.25rem;
            line-height: 1.225;
        }

        ${Description} {
            ${tw`mt-8 text-darkBlue`};
            font-size: 14px;
            line-height: 1.57;

            strong {
                ${tw`inline-block block mt-8`};
            }

            ul {
                ${tw`list-disc mt-2 pl-12`};

                li {
                    ${tw`py-1`};

                    &:last-child {
                        ${tw`pb-0`};
                    }
                }
            }
        }
    }

    ${Right} {
        ${tw`flex flex-col items-start w-1/3`};

        ${media.tabPor`
            ${tw`w-2/5`};
        `}

        ${media.mob`
            ${tw`w-full mt-16`};
        `}

        ${Button}, ${ExternalLink} {
            ${tw`text-center py-3`};
            min-width: 220px;

            ${media.tabPor`
                min-width: 160px;
            `}

            &:not(:first-of-type) {
                ${tw`mt-4`};
            }

            &:last-of-type{
                ${tw`mb-8`};
            }
        }
    }
`

// Main Sidebar (Process List)

export const ProcessList = styled.div`
    ${tw`w-full p-6 pb-12 bg-darkBlue`};
    ${boxShadow};

    > ${Heading} {
        ${tw`mb-4 text-orange`};
        font-weight: 600; /* semibold */
        font-size: 1.25rem;
    }

    ${Button} {
        ${tw`mt-10 mx-auto`};
    }
`

export const Process = styled.div`
    ${tw`py-2 text-white`};

    ${Heading} {
        font-weight: 700; /* bold */
        font-size: 14px;
        line-height: 1.58;
    }

    ${Description} {
        font-weight: 400; /* regular */
        font-size: 14px;
        line-height: 1.58;
    }
`
